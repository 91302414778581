/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

function SEO({ description, lang, meta, title }) {
  const i18n = useContext(I18nContext)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            titleDE
            titleEN
            descriptionDE
            descriptionEN
            author
          }
        }
      }
    `
  )

  const localizedTitle =
    i18n.currentLocale === 'de'
      ? site.siteMetadata.titleDE
      : site.siteMetadata.titleEN

  const localizedDescription =
    i18n.currentLocale === 'de'
      ? site.siteMetadata.descriptionDE
      : site.siteMetadata.descriptionEN

  const metaTitle = title || localizedTitle
  const metaDescription = description || localizedDescription

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || i18n.currentLocale || 'en',
      }}
      title={metaTitle}
      titleTemplate={`%s | ${localizedTitle}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: '',
  meta: [],
  description: '',
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
