import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'

import title1Styles from '../styles/title1'
import title2Styles from '../styles/title2'
import labelStyles from '../styles/label'

const Wrapper = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.primary};
  margin: 0 0 2.5rem 0;
`

const Headline = styled.h1`
  ${title1Styles}
  margin: 0;
  padding: 0;
  font-weight: normal;

  ${props =>
    props.size === 'small' &&
    css`
      ${title2Styles}
    `}
`

const Top = styled.div`
  ${labelStyles}
  display: inline-block;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  width: 168px;
  position: relative;
`

const Line = styled(animated.div)`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.primary};
  position: absolute;
  bottom: 0;
  display: block;
`

const Title = ({ children, top, line = true, size = 'medium' }) => {
  const [ref, inView] = useInView()
  const lineProps = useSpring({
    transform: inView ? `scaleX(1)` : `scaleX(0.2)`,
  })

  return (
    <Wrapper ref={ref}>
      <Top inView={inView}>
        {top}
        {line && <Line style={lineProps} />}
      </Top>
      <Headline size={size}>{children}</Headline>
    </Wrapper>
  )
}

Title.propTypes = {
  top: PropTypes.string,
}

export default Title
