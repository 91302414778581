import { css } from 'styled-components'

import title1Styles from './title1'

export default css`
  ${title1Styles}
  font-size: 2.25rem;

  @media (max-width: 540px) {
    font-size: 2rem;
  }

  @media (max-width: 380px) {
    font-size: 1.85rem;
  }
`
