import styled, { css } from 'styled-components'

const Text = styled.div`
  ${props =>
    props.align === 'center' &&
    css`
      text-align: center;
    `}

  @media (min-width: 680px) {
    ${props =>
      props.columns === 2 &&
      css`
        columns: 2;
        column-gap: 20px;
      `}
  }

  ${props =>
    props.breakInside === 'avoid' &&
    css`
      > * {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    `}

  ${props =>
    props.centerLists &&
    css`
      ul {
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
      }
    `}
`

export default Text
