import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import Content from '../../components/Content'
import Title from '../../components/Title'
import Text from '../../components/Text'

const ContactSuccessPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'contact.title' })} />
      <Content intro>
        <Title top={intl.formatMessage({ id: 'contact.title' })}>
          <FormattedMessage id="contact.success.title" />
        </Title>
        <Text align="center">
          <FormattedMessage id="contact.success.content" />
        </Text>
      </Content>
    </Layout>
  )
}

export default ContactSuccessPage

export const i18n = {
  en: '/contact/success',
  de: '/kontakt/erfolg',
}
